import React, { useState, useEffect } from "react"

const Logo = props => {
  const [isShown, setIsShown] = useState(false)
  const [color, setColor] = useState("#FFF")
  const colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ]
  const random = Math.floor(Math.random() * colors.length)

  useEffect(() => {
    setColor(colors[random])
    updateThemeColor()
    return () => {}
  }, [])

  const updateThemeColor = () => {
    document.body.setAttribute("style", `--theme: ${colors[random]}`)
  }
  const hover = () => {
    setIsShown(true)
    const timer = setTimeout(() => {
      if (!isShown) {
        setColor(colors[random])
        setIsShown(false)
        updateThemeColor()
      }
    }, 1000)
    return () => clearTimeout(timer)
  }
  return (
    <>
      <svg
        id="mask-main"
        width={201}
        height={48}
        version="1.1"
        fill="none"
        onMouseEnter={() => hover()}
        onTouchStart={() => hover()}
        // mask={'url(#MASK)'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          mask={"url(#MASK)"}
          height={110}
          width={360}
        >
          <g fill={color}>
            <path d="M4.234 9.062c0-3.963-.4-4.498-2.719-5.099L0 3.563l.067-.268L13.572 0v21.241l.268.2c2.384-3.25 6.285-6.145 10.407-6.145 6.553 0 11.924 6.079 11.924 14.228 0 10.932-9.138 15.942-16.492 15.942-3.588 0-6.62-.935-9.606-2.049h-.066L4.636 46h-.402V9.062Zm21.73 22.844c0-6.88-2.72-11.845-7.221-11.845-2.318 0-3.722 1.136-5.17 2.249v16.543c1.85 3.317 4.702 5.967 6.752 5.967 3.655 0 5.639-5.9 5.639-12.914ZM54.735 38.92l-.134-.134c-3.254 3.785-6.15 6.413-9.739 6.413-3.833 0-6.82-3.118-6.82-6.235 0-5.032 4.302-8.082 16.827-11.444l.066-2.45c.201-4.697-2.25-8.282-6.685-8.282-2.519 0-3.254.601-3.254 1.38 0 .602 2.25 2.516 2.25 4.23 0 2.25-1.916 3.964-4.501 3.964-2.251 0-4.234-1.202-4.234-3.585 0-5.032 9.672-7.815 15.377-7.815 8.157 0 10.675 3.318 10.475 10.064l-.535 12.046c-.134 2.65 1.136 3.83 2.585 3.83 1.047 0 2.05-1.203 2.786-2.25l.468.2c-1.783 2.183-4.97 6.347-8.803 6.347-3.455 0-5.505-2.45-6.084-6.346m-.268-.802.468-9.863c-5.705 1.514-7.756 3.696-7.756 7.214 0 2.65 1.248 4.564 3.767 4.564 1.448 0 2.518-.868 3.521-1.915ZM70.358 44.152l.802-.067c2.05-.133 2.786-.267 2.786-1.046v-18.28c0-4.097-.468-4.631-2.72-5.232l-1.515-.334.067-.268 13.773-3.762-.2 10.464h.133c2.05-7.08 4.837-10.598 9.004-10.598 2.986 0 5.237 1.781 5.237 5.166 0 2.182-1.916 4.497-4.435 4.497-3.053 0-4.502-2.315-5.17-4.497-1.984.133-3.32 2.65-4.77 8.46v14.361c0 .535.602.869 3.633 1.047l.87.067.2.4H70.157l.2-.4v.022Z" />
            <path d="M99.843 9.062c0-3.963-.401-4.498-2.72-5.099l-1.515-.4.067-.268 13.506-3.317V21.24l.267.2c2.385-3.25 6.285-6.145 10.408-6.145 6.552 0 11.923 6.079 11.923 14.228 0 10.932-9.137 15.942-16.492 15.942-3.566 0-6.619-.935-9.605-2.049h-.067L100.244 46h-.401V9.062Zm21.729 22.844c0-6.88-2.719-11.845-7.221-11.845-2.318 0-3.699 1.136-5.17 2.249v16.543c1.85 3.317 4.702 5.967 6.752 5.967 3.633 0 5.639-5.9 5.639-12.914ZM134.297 30.837c0-9.262 7.288-15.942 16.225-15.942 8.491 0 11.856 5.5 11.856 9.597 0 .467-.067.868-.134 1.269 0 0-14.174-.2-17.896-.334-.133.802-.066 2.048-.066 2.85 0 7.013 3.766 12.112 9.939 12.112 3.767 0 6.419-1.714 8.023-5.165l.402.2c-2.519 6.546-6.954 10.197-14.108 10.197-8.156 0-14.241-6.412-14.241-14.761m19.412-6.168c0-3.963-.736-8.861-4.101-8.861-2.318 0-4.636 3.117-5.17 8.861h9.271ZM165.431 44.152l.802-.067c2.051-.133 2.786-.267 2.786-1.046v-18.28c0-4.097-.468-4.631-2.719-5.232l-1.515-.334.067-.268 13.772-3.762-.2 10.464h.134c2.05-7.08 4.836-10.598 9.003-10.598 2.987 0 5.238 1.781 5.238 5.166 0 2.182-1.917 4.497-4.435 4.497-3.054 0-4.502-2.315-5.171-4.497-1.983.133-3.321 2.65-4.769 8.46v14.361c0 .535.602.869 3.633 1.047l.869.067.2.4H165.23l.201-.4v.022ZM191.127 40.233c0-2.582 2.251-4.764 4.97-4.764S201 37.783 201 40.233c0 2.784-2.318 5.032-4.903 5.032-2.719 0-4.97-2.248-4.97-5.032Z" />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h201v46H0z" />
            </clipPath>
          </defs>
        </svg>

        <mask id="MASK" height={110} width={360}>
          <image
            style={{ height: "116px" }}
            xlinkHref={
              isShown
                ? "https://cdn.shopify.com/s/files/1/0329/6696/7431/files/transition-out.gif?v=1669950687"
                : "https://cdn.shopify.com/s/files/1/0329/6696/7431/files/transition-in.gif?v=1669950688"
            }
          />
        </mask>
      </svg>
    </>
  )
}

export default Logo
