/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Marquee from "react-fast-marquee"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="announcement">
        <Marquee>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            NOW WORKING AS A SENIOR FRONT-END DEVELOPER AT...
            <a href="https://theworkingparty.com.au/">THE WORKING PARTY</a>
          </span>
          <span>
            OK YOU WAITED THIS LONG. FOR FREELANCE ENQUIRIES PLEASE EMAIL ME
            AT...
            <a href="mailto:wbarberdesign@gmail.com">WBARBERDESIGN@GMAIL.COM</a>
          </span>
        </Marquee>
      </div>
      <div className="main-grid">
        <Header />
        <main>{children}</main>
      </div>
      <footer
        style={{
          marginTop: `var(--space-5)`,
          fontSize: `var(--font-sm)`,
        }}
      ></footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
