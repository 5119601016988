import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./Graphics/logo"

const Header = ({ siteTitle }) => (
  <header>
    <Logo />
    <h2>Website Design & Development.</h2>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
